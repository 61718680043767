import {
  DownArrowSmallIcon,
  Flex,
  UpArrowSmallIcon,
  useBoolean,
} from '@hummingbird/shared';

import { NameContainer } from './MenuItem.styled';

interface Props {
  children: React.ReactNode;
  name: string;
  levelOfNest: number;
}

const MenuItem = ({ name, children, levelOfNest }: Props) => {
  const { value: isExpanded, toggle: toggleIsExpanded } = useBoolean(false);

  return (
    <>
      <NameContainer
        $isExpanded={isExpanded}
        $levelOfNest={levelOfNest}
        aria-expanded={isExpanded}
        role="menuitem"
        onClick={toggleIsExpanded}>
        <span>{name}</span>
        {isExpanded ? <UpArrowSmallIcon /> : <DownArrowSmallIcon />}
      </NameContainer>

      {isExpanded && <Flex direction="column">{children}</Flex>}
    </>
  );
};

export default MenuItem;
