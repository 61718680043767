import { fontSizes, fontWeights, colors } from '@hummingbird/shared';
import styled from 'styled-components';

interface NameContainerProps {
  $isExpanded: boolean;
  $levelOfNest: number;
}

export const NameContainer = styled.button<NameContainerProps>`
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  justify-content: space-between;
  min-height: 40px;
  padding-left: ${({ $levelOfNest }) => $levelOfNest * 16}px;
  color: ${colors.night};
  font-weight: ${({ $isExpanded }) =>
    $isExpanded ? fontWeights.medium : fontWeights.regular};
  font-size: ${fontSizes.m};
  text-transform: uppercase;
  cursor: pointer;

  svg {
    width: 24px;
    height: 24px;
    fill: ${colors.navy};
  }
`;
