import { useMsal } from '@azure/msal-react';
import {
  Flex,
  LogoutIcon,
  UserIcon,
  useOnClickOutside,
} from '@hummingbird/shared';
import { useEffect, useRef, useState } from 'react';
import { FocusOn } from 'react-focus-on';
import { useTranslation } from 'react-i18next';
import { useIntercom } from 'react-use-intercom';

import { MenuList, MenuListItem, SettingsButton } from './SettingsMenu.styled';

import { action } from 'services/analytics/constants';
import useGoogleAnalytics from 'services/analytics/useGoogleAnalytics';
import { clearUserSession } from 'services/auth';

const SettingsMenu = () => {
  const { instance } = useMsal();
  const { t } = useTranslation();
  const { sendAccountEvent } = useGoogleAnalytics();
  const { shutdown } = useIntercom();

  const [isOpen, setOpen] = useState(false);

  const ref = useRef<HTMLDivElement>(null);

  const closeMenu = () => {
    setOpen(false);
  };

  useOnClickOutside(ref, closeMenu);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape' && isOpen) {
        closeMenu();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen]);

  const handleLogout = () => {
    instance.logoutRedirect();
    clearUserSession();
    shutdown();

    sendAccountEvent({
      action: action.account.loggedOut,
    });
  };

  return (
    <Flex gap={12} ref={ref}>
      <SettingsButton
        icon={UserIcon}
        label={t('labels.settingsMenu')}
        size="big"
        variant="outlined"
        onClick={() => setOpen(prevOpen => !prevOpen)}
      />

      {isOpen && (
        <FocusOn
          allowPinchZoom={true}
          autoFocus={false}
          onClickOutside={closeMenu}
          onEscapeKey={closeMenu}>
          <MenuList>
            <MenuListItem
              aria-label={t('general.logout')}
              onClick={handleLogout}>
              <LogoutIcon />

              {t('general.logout')}
            </MenuListItem>
          </MenuList>
        </FocusOn>
      )}
    </Flex>
  );
};

export default SettingsMenu;
