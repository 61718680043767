import {
  Dropdown,
  Spinner,
  fontSizes,
  fontWeights,
  colors,
} from '@hummingbird/shared';
import styled from 'styled-components';

import { boxShadows } from 'theme';

export const SidebarContainer = styled.div`
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 12px;
  width: 288px;

  @media (max-width: 480px) {
    width: 226px;
  }
`;

export const UserData = styled.div.attrs({ id: 'user-menu' })`
  display: flex;
  flex-direction: column;
  padding: 16px 20px;
  background-color: ${colors.white};
  border-radius: 12px;
  box-shadow: ${boxShadows.elevation2};
`;

export const WelcomeNote = styled.span`
  margin: 12px 0 20px;
  color: ${colors.night};
  font-weight: ${fontWeights.regular};
  font-size: ${fontSizes.m};
`;

export const FundDropdown = styled(Dropdown)`
  margin-top: 16px;

  label span {
    margin: 0;
    padding: 0 0 2px 4px;
    color: ${colors.mouse};
    font-weight: ${fontWeights.regular};
    font-size: ${fontSizes.xs};
  }
`;

export const LoaderContainer = styled.div`
  position: relative;
`;

export const SpinnerIcon = styled(Spinner)`
  position: absolute;
  top: 54px;
  left: 14px;
  z-index: 1;
  animation: spin 1s linear infinite;
`;

export const SettingsMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: auto;
`;
