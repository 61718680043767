import { Button, SpinnerIcon as BaseSpinnerIcon } from '@hummingbird/shared';
import styled from 'styled-components';

export const SpinnerIcon = styled(BaseSpinnerIcon)`
  width: 16px !important;
  height: 16px !important;
  padding: 12px 16px;
  animation: spin 1s linear infinite;
`;

export const Container = styled(Button)`
  min-width: unset;
  padding: 0;
  pointer-events: none;
`;
